import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AgoraRTC from "agora-rtc-sdk-ng";

import {
  LocalUser,
  RemoteUser,
  useJoin,
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRemoteAudioTracks,
  useRemoteUsers,
} from "agora-rtc-react";
import ScreenShare from "./ShareIng";
import VideoShartest from "./VideoShartest";

export const LiveVideo = () => {
  const appId = "6857853f99024834a4b38c48aae72c28";
  const { channelName } = useParams();
  const [activeConnection, setActiveConnection] = useState(true);
  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const [screenSharing, setScreenSharing] = useState(false);
  const [token, setToken] = useState(null);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);

  // ?** local video user
  const [screenSharingTracks, setScreenSharingTracks] = useState({});

  const [isSharingScreen, setIsSharingScreen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          "https://ottadmin.imboxocinema.com/api/agora-token",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ channelName, userAccount: 129 }),
          }
        );
        const data = await response.json();
        setToken(data.data.token);
      } catch (error) {
        console.error("Failed to fetch token:", error);
      }
    };

    fetchToken();
  }, [channelName]);

  useJoin(
    {
      appid: appId,
      channel: channelName,
      token: token,
    },
    activeConnection
  );

  usePublish([localMicrophoneTrack, localCameraTrack]);

  const remoteUsers = useRemoteUsers();

  console.log("remoteUsers", remoteUsers);

  const { audioTracks } = useRemoteAudioTracks(remoteUsers);

  audioTracks.forEach((track) => track.play());

  return (
    <>
      <div className="videoApp">
        <a href="#" class="btn btn_live">
          Live<span class="live-icon"></span>
        </a>
        {/* <div className="sharingScreen">
          <video ref={sharingScVideo} muted autoPlay></video>
          <video ref={sharingScVideoforReomet} muted autoPlay></video>
        </div> */}
        <div
          className={
            remoteUsers.length >= 1 ? "livestreem localVideo" : "localVideo"
          }
        >
          <div id="remoteVideoGrid">
            <span className="watching">
              <i class="fa-solid fa-eye"></i> {remoteUsers.length}
            </span>
            {remoteUsers.map((user) => (
              <div
                key={user.uid}
                className={
                  screenSharingTracks === user.uid
                    ? "remote-video-container sharingScreen"
                    : "remote-video-container"
                }
              >
                <RemoteUser user={user} />
              </div>
            ))}
          </div>
          {/* Display local user */}
          <LocalUser
            audioTrack={localMicrophoneTrack}
            videoTrack={localCameraTrack}
            cameraOn={cameraOn && !screenSharing} // Ensure camera video shows when not screen sharing
            micOn={micOn}
            playAudio={false}
            playVideo={cameraOn && !screenSharing}
            className="mywon"
          />
          <div>
            <div id="controlsToolbar">
              <div id="mediaControls">
                <button className="btn" onClick={() => setMic((a) => !a)}>
                  {micOn ? (
                    <i class="fa-solid fa-microphone"></i>
                  ) : (
                    <i class="fa-solid fa-microphone-slash"></i>
                  )}
                </button>
                <button className="btn" onClick={() => setCamera((a) => !a)}>
                  {cameraOn ? (
                    <i class="fa-solid fa-video"></i>
                  ) : (
                    <i class="fa-solid fa-video-slash"></i>
                  )}
                </button>

                <button>
                  {/* <VideoShartest
                    token={token}
                    appId={appId}
                    channelName={channelName}
                  /> */}
                  <ScreenShare
                    appId={appId}
                    channelName={channelName}
                    setScreenSharingTracks={setScreenSharingTracks}
                    isSharingScreen={isSharingScreen}
                    setIsSharingScreen={setIsSharingScreen}
                  />
                </button>

                {/* <button
                className="btn"
                onClick={() =>
                  screenSharing ? stopScreenShare() : startScreenShare()
                }
              >
                {screenSharing ? "Stop Screen Share" : "Start Screen Share"}
              </button> */}
              </div>
              <button
                id="endConnection"
                onClick={() => {
                  setActiveConnection(false);
                  navigate("/");
                }}
              >
                {" "}
                <i class="fa-solid fa-link-slash"></i>
              </button>

              <div className="sideBtns">
                <ul>
                  <li>
                    <button className="btn btn-outline-info">
                      <i class="fa-solid fa-users"></i>
                    </button>
                  </li>
                  <li>
                    <button className="btn btn-outline-info">
                      <i class="fa-solid fa-comment-dots"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
