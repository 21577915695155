import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ConnectForm } from "./components/ConnectForm";
import { LiveVideo } from "./components/LiveVideo";

import AgoraRTC, { AgoraRTCProvider, useRTCClient } from "agora-rtc-react";

import "./App.css";

function App() {
  const agoraClient = useRTCClient(
    AgoraRTC.createClient({ codec: "vp8", mode: "rtc" })
  );

  return (
    <Router>
      {" "}
      {/* Wrap the whole component tree with Router */}
      <Routes>
        <Route path="/" element={<ConnectForm />} />
        <Route
          path="/via/:channelName"
          element={
            <AgoraRTCProvider client={agoraClient}>
              <LiveVideo />
            </AgoraRTCProvider>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
