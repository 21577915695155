import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import logo from '../logo.svg';

export const ConnectForm = () => {
  const [channelName, setChannelName] = useState('');
  const [invalidInputMsg, setInvalidInputMsg] = useState('');
  const navigate = useNavigate();

  const handleConnect = (e) => {
    e.preventDefault();

    const trimmedChannelName = channelName.trim();
    
    if (trimmedChannelName === '') {
      setInvalidInputMsg("Channel name can't be empty.");
      setChannelName(''); 
      return;
    }

    navigate(`/via/${trimmedChannelName}`); 
  };

  return (
    <form onSubmit={handleConnect}>
      <img src={logo} className="logo" alt="logo" />
      <div className="card">
         <div>
         <input
          id="channelName"
          type="text"
          placeholder="Channel Name"
          value={channelName}
          onChange={(e) => {
            setChannelName(e.target.value);
            setInvalidInputMsg('');
          }}
        />
        <button>Connect</button>
        {invalidInputMsg && <p style={{ color: 'red' }}>{invalidInputMsg}</p>}

         </div>
      </div>
    </form>
  );
};
